import React from "react";

export const DashboardIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.82116 1.82141C1.5086 2.13397 1.33301 2.55789 1.33301 2.99992V4.66659C1.33301 5.10861 1.5086 5.53254 1.82116 5.8451C2.13372 6.15766 2.55765 6.33325 2.99967 6.33325H4.66634C5.10837 6.33325 5.53229 6.15766 5.84485 5.8451C6.15741 5.53254 6.33301 5.10861 6.33301 4.66659V2.99992C6.33301 2.55789 6.15741 2.13397 5.84485 1.82141C5.53229 1.50885 5.10837 1.33325 4.66634 1.33325H2.99967C2.55765 1.33325 2.13372 1.50885 1.82116 1.82141Z"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1545 1.82141C9.84194 2.13397 9.66634 2.55789 9.66634 2.99992V4.66659C9.66634 5.10861 9.84194 5.53254 10.1545 5.8451C10.4671 6.15766 10.891 6.33325 11.333 6.33325H12.9997C13.4417 6.33325 13.8656 6.15766 14.1782 5.8451C14.4907 5.53254 14.6663 5.10861 14.6663 4.66659V2.99992C14.6663 2.55789 14.4907 2.13397 14.1782 1.82141C13.8656 1.50885 13.4417 1.33325 12.9997 1.33325H11.333C10.891 1.33325 10.4671 1.50885 10.1545 1.82141Z"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.82116 10.1547C1.5086 10.4673 1.33301 10.8912 1.33301 11.3333V12.9999C1.33301 13.4419 1.5086 13.8659 1.82116 14.1784C2.13372 14.491 2.55765 14.6666 2.99967 14.6666H4.66634C5.10837 14.6666 5.53229 14.491 5.84485 14.1784C6.15741 13.8659 6.33301 13.4419 6.33301 12.9999V11.3333C6.33301 10.8912 6.15741 10.4673 5.84485 10.1547C5.53229 9.84218 5.10837 9.66658 4.66634 9.66658H2.99967C2.55765 9.66658 2.13372 9.84218 1.82116 10.1547Z"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1545 10.1547C9.84194 10.4673 9.66634 10.8912 9.66634 11.3333V12.9999C9.66634 13.4419 9.84194 13.8659 10.1545 14.1784C10.4671 14.491 10.891 14.6666 11.333 14.6666H12.9997C13.4417 14.6666 13.8656 14.491 14.1782 14.1784C14.4907 13.8659 14.6663 13.4419 14.6663 12.9999V11.3333C14.6663 10.8912 14.4907 10.4673 14.1782 10.1547C13.8656 9.84218 13.4417 9.66658 12.9997 9.66658H11.333C10.891 9.66658 10.4671 9.84218 10.1545 10.1547Z"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
