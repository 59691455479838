import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Badge, Pagination } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import moment from "moment";

import {
  onGetWithdrawRequestList,
  WithdrawRequest,
  WithdrawStatus,
  WithdrawQueryInput,
} from "./service";

const List = () => {
  const [params, setParams] = useState<WithdrawQueryInput>({
    page: 1,
    limit: 10,
    status: [WithdrawStatus.SUCCESS, WithdrawStatus.REJECT],
  });
  const [data, setData] = useState<WithdrawRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginateItems, setPaginateItems] = useState<any[]>([]);

  const handleGetWithdrawRequestList = async (params: WithdrawQueryInput) => {
    setLoading(true);
    const res = await onGetWithdrawRequestList(params);
    if (res && res.data) {
      setLoading(false);
      setData(res.data);
      let active = params.page;
      let paginateItems = [];
      for (
        let number = 1;
        number <= Math.round(res.total / params.limit);
        number++
      ) {
        paginateItems.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => onChangePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      setPaginateItems(paginateItems);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetWithdrawRequestList(params);
  }, [params]);

  const onChangePage = (page: number) => {
    setParams({ ...params, page });
  };

  return (
    <div>
      <div className="title-page">Withdraw Request</div>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>Creator</th>
                  <th>Created Time</th>
                  <th>Amount</th>
                  <th>Approved Time</th>
                  <th>Rejected Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((item: WithdrawRequest, index: number) => {
                    let badgeBg = "success";
                    switch (item.status) {
                      case WithdrawStatus.PENDING:
                        badgeBg = "info";
                        break;
                      case WithdrawStatus.REJECT:
                        badgeBg = "secondary";
                        break;
                      case WithdrawStatus.CANCELED:
                        badgeBg = "danger";
                        break;
                      default:
                        badgeBg = "success";
                        break;
                    }
                    return (
                      <tr key={index} className="text-center items-center">
                        <td
                          style={{
                            width: "50px",
                            fontSize: "13px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            width: "250px",
                            fontSize: "13px",
                          }}
                        >
                          <p style={{ marginBottom: 0 }}>{item.company.name}</p>
                        </td>
                        <td
                          style={{
                            width: "300px",
                            overflowX: "auto",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {moment(item.createdAt).format("HH:mm MM/DD/YYYY")}
                        </td>

                        <td
                          style={{
                            width: "250px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          <code>{item.amount.toFixed(2)}</code>
                        </td>
                        <td
                          style={{
                            width: "300px",
                            overflowX: "auto",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {item.acceptedAt
                            ? moment(item.acceptedAt).format("HH:mm MM/DD/YYYY")
                            : ""}
                        </td>
                        <td
                          style={{
                            width: "300px",
                            overflowX: "auto",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {item.rejectedAt
                            ? moment(item.rejectedAt).format("HH:mm MM/DD/YYYY")
                            : ""}
                        </td>
                        <td
                          style={{
                            width: "150px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          <Badge pill bg={badgeBg}>
                            {item.status}
                          </Badge>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {data.length <= 0 && (
              <div
                style={{ width: "100%", opacity: "0.8" }}
                className="text-center mt-3"
              >
                No data found
              </div>
            )}
            <Pagination size="sm">{paginateItems}</Pagination>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default List;
