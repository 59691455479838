import axiosClient from "../../utils/axiosClient";

export interface CreateNewCateAndFaqInput {
  cateTitle: string;
  faqTitle: string;
  faqContent: string;
}

export interface CreateNewFaqInput {
  categoryId: string;
  title: string;
  content: string;
}

export interface UpdateCategoryInput {
  title: string;
}

export interface UpdateFaqInput {
  title: string;
  content: string;
}

export interface FAQ {
  _id: string;
  title: string;
  content: string;
  categoryId: string;
  faqCate?: FAQCate;
  createdAt: string;
  updatedAt: string;
}

export interface FAQCate {
  _id: string;
  title: string;
  faqs?: FAQ[];
  createdAt: string;
  updatedAt: string;
}

export const createNewCateAndFaq = async (body: CreateNewCateAndFaqInput) => {
  return await axiosClient.post("faq/create-cate-and-faq", body);
};

export const createNewFaq = async (body: CreateNewFaqInput) => {
  return await axiosClient.post("faq/create-faq", body);
};

export const listFaqCategory = async () => {
  return await axiosClient.get("faq/list-cate");
};

export const updateFaqCategory = async (
  _id: string,
  body: UpdateCategoryInput
) => {
  return await axiosClient.put(`faq/update-category/${_id}`, body);
};

export const updateFaq = async (_id: string, body: UpdateFaqInput) => {
  return await axiosClient.put(`faq/update/${_id}`, body);
};

export const deleteFaqCategory = async (_id: string) => {
  return await axiosClient.delete(`faq/category/${_id}`);
};

export const deleteFaq = async (_id: string) => {
  return await axiosClient.delete(`faq/${_id}`);
};
