import React from "react";

export const WithdrawColorIcon = ({ width = 30, height = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      xmlSpace="preserve"
      style={{ marginRight: "4px" }}
    >
      <defs></defs>
      <g
        style={{
          stroke: "none",
          strokeWidth: 0,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <rect
          x="1"
          y="40.36"
          rx="0"
          ry="0"
          width="88"
          height="41.82"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(80,178,103)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
        />
        <circle
          cx="44.998"
          cy="61.268"
          r="13.748"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(163,232,176)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <circle
          cx="74.69399999999999"
          cy="61.264"
          r="4.624"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(163,232,176)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <circle
          cx="15.303999999999998"
          cy="61.264"
          r="4.624"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(163,232,176)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="  matrix(1 0 0 1 0 0) "
        />
        <path
          d="M 44.91 68.313 c -1.6 0 -3.109 -0.979 -3.703 -2.563 c -0.194 -0.517 0.068 -1.093 0.585 -1.287 c 0.519 -0.19 1.094 0.067 1.288 0.586 c 0.377 1.006 1.502 1.516 2.511 1.141 c 1.006 -0.378 1.519 -1.504 1.141 -2.512 c -0.216 -0.576 -0.682 -1.007 -1.276 -1.181 l -1.537 -0.562 c -1.142 -0.332 -2.084 -1.207 -2.523 -2.379 c -0.765 -2.038 0.272 -4.319 2.312 -5.085 c 2.038 -0.766 4.32 0.272 5.085 2.312 c 0.193 0.518 -0.068 1.094 -0.585 1.288 c -0.518 0.192 -1.094 -0.067 -1.288 -0.585 c -0.183 -0.488 -0.545 -0.876 -1.019 -1.091 c -0.474 -0.215 -1.002 -0.234 -1.491 -0.051 c -0.488 0.183 -0.875 0.545 -1.091 1.019 c -0.215 0.475 -0.233 1.004 -0.051 1.491 c 0.216 0.577 0.682 1.009 1.276 1.183 l 1.537 0.562 c 1.141 0.332 2.083 1.206 2.523 2.377 c 0.765 2.04 -0.272 4.321 -2.312 5.087 C 45.837 68.232 45.37 68.313 44.91 68.313 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 56.224 c -0.552 0 -1 -0.447 -1 -1 v -1.648 c 0 -0.553 0.448 -1 1 -1 s 1 0.447 1 1 v 1.648 C 46 55.776 45.552 56.224 45 56.224 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 69.959 c -0.552 0 -1 -0.447 -1 -1 V 67.31 c 0 -0.553 0.448 -1 1 -1 s 1 0.447 1 1 v 1.649 C 46 69.512 45.552 69.959 45 69.959 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 89 83.177 H 1 c -0.552 0 -1 -0.447 -1 -1 V 40.357 c 0 -0.552 0.448 -1 1 -1 h 88 c 0.553 0 1 0.448 1 1 v 41.819 C 90 82.729 89.553 83.177 89 83.177 z M 2 81.177 h 86 V 41.357 H 2 V 81.177 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 76.015 c -8.132 0 -14.748 -6.616 -14.748 -14.748 S 36.868 46.519 45 46.519 c 8.132 0 14.748 6.616 14.748 14.748 S 53.132 76.015 45 76.015 z M 45 48.519 c -7.029 0 -12.748 5.719 -12.748 12.748 S 37.971 74.015 45 74.015 s 12.748 -5.719 12.748 -12.748 S 52.029 48.519 45 48.519 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 74.696 66.892 c -3.102 0 -5.625 -2.523 -5.625 -5.625 s 2.523 -5.624 5.625 -5.624 s 5.624 2.522 5.624 5.624 S 77.798 66.892 74.696 66.892 z M 74.696 57.643 c -1.999 0 -3.625 1.626 -3.625 3.624 c 0 1.999 1.626 3.625 3.625 3.625 c 1.998 0 3.624 -1.626 3.624 -3.625 C 78.32 59.269 76.694 57.643 74.696 57.643 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 15.304 66.892 c -3.102 0 -5.625 -2.523 -5.625 -5.625 s 2.523 -5.624 5.625 -5.624 s 5.625 2.522 5.625 5.624 S 18.406 66.892 15.304 66.892 z M 15.304 57.643 c -1.999 0 -3.625 1.626 -3.625 3.624 c 0 1.999 1.626 3.625 3.625 3.625 s 3.625 -1.626 3.625 -3.625 C 18.929 59.269 17.303 57.643 15.304 57.643 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 33.985 c -0.552 0 -1 -0.448 -1 -1 V 7.823 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 v 25.162 C 46 33.538 45.552 33.985 45 33.985 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 34.001 33.985 c -0.552 0 -1 -0.448 -1 -1 V 15.522 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 v 17.463 C 35.001 33.538 34.554 33.985 34.001 33.985 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 55.998 33.985 c -0.553 0 -1 -0.448 -1 -1 V 15.522 c 0 -0.552 0.447 -1 1 -1 s 1 0.448 1 1 v 17.463 C 56.998 33.538 56.551 33.985 55.998 33.985 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 66.997 33.985 c -0.553 0 -1 -0.448 -1 -1 v -9.764 c 0 -0.552 0.447 -1 1 -1 s 1 0.448 1 1 v 9.764 C 67.997 33.538 67.55 33.985 66.997 33.985 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 23.003 33.985 c -0.552 0 -1 -0.448 -1 -1 v -9.764 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 v 9.764 C 24.003 33.538 23.555 33.985 23.003 33.985 z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeMiterlimit: 10,
            fill: "rgb(0,0,0)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
