import axiosClient from "../../utils/axiosClient";

export enum WithdrawStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  REJECT = "REJECT",
  CANCELED = "CANCELED",
}
export interface WithdrawQueryInput {
  page: number;
  limit: number;
  status: WithdrawStatus[];
}

export interface Company {
  _id: string;
  name?: string;
  email?: string;
}

export interface WithdrawRequest {
  _id: string;
  createdBy: string;
  company: Company;
  amount: number;
  status: WithdrawStatus;
  rejectedAt: Date;
  acceptedAt: Date;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
}

export const onGetWithdrawRequestList = async (params: WithdrawQueryInput) => {
  return await axiosClient.get("withdraw-request", params);
};

export const onChangeWithdrawRequestStatus = async (
  _id: string,
  data: { status: WithdrawStatus }
) => {
  return await axiosClient.put(`/withdraw-request/change-status/${_id}`, data);
};
