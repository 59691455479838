import { Media } from "../../services/media";
import axiosClient from "../../utils/axiosClient";

export interface Configs {
  _id: string;
  aboutPageSummary: string;
  aboutPageContent: string;
  aboutPageMediaIds: string[];
  aboutPageMedias: Media[];
}

export interface ConfigInput {
  aboutPageSummary?: string;
  aboutPageContent?: string;
  aboutPageMediaId?: string;
}

export interface Post {
  _id: string;
  title: string;
  content: string;
  avatarId: string;
  avatar: Media;
  createdAt: string;
  updatedAt: string;
}

export enum PostStatusCode {
  POST_NOT_FOUND = "POST_NOT_FOUND",
  POST_ALREADY_EXISTED = "POST_ALREADY_EXISTED",
}

export interface CreatePostInput {
  title: string;
  content: string;
  avatarId: string;
}

export interface UpdatePostInput {
  title?: string;
  content?: string;
  avatarId?: string;
}

export const modifyConfig = async (body: ConfigInput) => {
  return await axiosClient.post("config", body);
};

export const getConfig = async () => {
  return await axiosClient.get("config");
};

export const removeAboutPageMedia = async (mediaId: string) => {
  return await axiosClient.delete(`config/remove/about-page/media/${mediaId}`);
};

export const getListPost = async () => {
  return await axiosClient.get("post");
};

export const createPost = async (body: CreatePostInput) => {
  return await axiosClient.post("post", body);
};

export const updatePost = async (_id: string, body: UpdatePostInput) => {
  return await axiosClient.put(`post/update/${_id}`, body);
};

export const deletePost = async (_id: string) => {
  return await axiosClient.delete(`post/${_id}`);
};
