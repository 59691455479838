import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useAuth } from "../components/AuthContext";
import AvatarHeader from "../components/AvatarHeader";
import localStorageHelper, { KeyStorage } from "../utils/localStorage";
import { Session } from "../utils/session";
import "react-datepicker/dist/react-datepicker.css";
import {
  DashboardIcon,
  CampaignIcon,
  WithdrawNonColorIcon,
  WithdrawColorIcon,
  CartIcon,
} from "../components/Icons";
import { TagOutlined, SolutionOutlined } from "@ant-design/icons";

const LayoutAdmin: React.FC = () => {
  const navigate = useNavigate();

  const auth = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    const dataSection: Session = localStorageHelper.getObject(
      KeyStorage.SESSION
    );
    if (isEmpty(dataSection)) {
      navigate("/auth/login", { replace: true });
    }
  }, [pathname]);

  const onLogOut = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <button className="btn-close" onClick={onClose}>
              x
            </button>
            <div className="title">Log Out</div>
            <div className="content">Are you sure you want to log-off?</div>

            <div className="mt-3" style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  onClose();
                  auth?.logoutAdmin();
                  navigate("/auth/login", { replace: true });
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                variant="danger"
                size="sm"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        );
      },
      overlayClassName: "overlayClassName",
      closeOnClickOutside: false,
    });
  };

  return (
    <Row className="menu-layout">
      <Col xs={2} className="bg-menu">
        <div className="layout-cms mb-2">
          <div
            className="oulet-auth cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="logo" height={50} />
          </div>
        </div>

        <Link
          to={"company"}
          className={`menu-list text-decoration-none ${
            pathname === "/company" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <DashboardIcon />
            </div>
            <div className="menu-label">Company</div>
          </div>
        </Link>

        <Link
          to={"campaign"}
          className={`menu-list text-decoration-none 
                    ${
                      pathname === "/campaign" ||
                      pathname === "/campaign/running" ||
                      pathname === "/campaign/expired"
                        ? "active-menu"
                        : ""
                    }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <CampaignIcon />
            </div>
            <div className="menu-label">Campaign</div>
          </div>
        </Link>

        <Link
          to={"pending-withdraw-request"}
          className={`menu-list text-decoration-none ${
            pathname === "/pending-withdraw-request" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <WithdrawNonColorIcon />
            </div>
            <div className="menu-label">Pending Withdraw Request</div>
          </div>
        </Link>

        <Link
          to={"withdraw-request"}
          className={`menu-list text-decoration-none ${
            pathname === "/withdraw-request" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <WithdrawColorIcon width={21} height={21} />
            </div>
            <div className="menu-label">Withdraw Request</div>
          </div>
        </Link>
        <Link
          to={"faq"}
          className={`menu-list text-decoration-none ${
            pathname === "/faq" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <TagOutlined style={{ color: "gray", fontSize: "21px" }} />
            </div>
            <div className="menu-label">FAQs</div>
          </div>
        </Link>
        <Link
          to={"about"}
          className={`menu-list text-decoration-none ${
            pathname === "/about" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <SolutionOutlined style={{ color: "gray", fontSize: "21px" }} />
            </div>
            <div className="menu-label">About</div>
          </div>
        </Link>
        <Link
          to={"order"}
          className={`menu-list text-decoration-none ${
            pathname === "/order" ? "active-menu" : ""
          }`}
        >
          <div className="menu-item">
            <div className="menu-icon">
              <CartIcon />
            </div>
            <div className="menu-label">Orders</div>
          </div>
        </Link>
      </Col>
      <Col xs={10} className="p-0">
        <div className="header-cms">
          <AvatarHeader />
          <Button className="btn-danger" onClick={onLogOut}>
            LOG OUT
          </Button>
        </div>

        <div className="content-outlet">
          <Outlet />
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(LayoutAdmin);
