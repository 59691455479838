import React, { useState, useEffect } from "react";
import { Col, Row, Spinner, Button } from "react-bootstrap";
import { Modal, Form, Input, Collapse, Popconfirm } from "antd";
import {
  createNewCateAndFaq,
  listFaqCategory,
  FAQCate,
  createNewFaq,
  CreateNewFaqInput,
  updateFaqCategory,
  UpdateCategoryInput,
  FAQ,
  updateFaq,
  deleteFaq,
  deleteFaqCategory,
} from "./service";
import { truncateString } from "../../utils/utils";

const { Panel } = Collapse;

const FAQsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<FAQCate[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalNewFaqOpen, setIsModalNewFaqOpen] = useState(false);
  const [selectedCate, setSelectedCate] = useState<FAQCate>();
  const [enableEditCate, setEnableEditCate] = useState<boolean>(false);
  const [selectedCateId, setSelectedCateId] = useState<string>("");

  const [selectedFaq, setSelectedFaq] = useState<FAQ>();
  const [isModalEditFaqOpen, setIsModalFaqOpen] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [newFaqForm] = Form.useForm();
  const [editCateForm] = Form.useForm();
  const [editFaqForm] = Form.useForm();

  const handleGetListFaqCategory = async () => {
    setLoading(true);
    const res = await listFaqCategory();
    if (res.data && res) {
      setData(res.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetListFaqCategory();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showModalNewFaq = (cate: FAQCate) => {
    setIsModalNewFaqOpen(true);
    setSelectedCate(cate);
  };

  const showModalEditFaq = (faq: FAQ) => {
    setIsModalFaqOpen(true);
    setSelectedFaq(faq);
    editFaqForm.setFieldsValue({
      title: faq.title,
      content: faq.content,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleCancelNewFaqModal = () => {
    setIsModalNewFaqOpen(false);
    newFaqForm.resetFields();
    setSelectedCate(undefined);
  };

  const handleCancelEditFaqModal = () => {
    setIsModalFaqOpen(false);
    editFaqForm.resetFields();
    setSelectedFaq(undefined);
  };

  const handleAddNewCateAndFaq = async (values: any) => {
    setLoading(true);
    if (!values.faqTitle || !values.faqContent) return;

    const res = await createNewCateAndFaq({
      cateTitle: values.cateTitle,
      faqTitle: values.faqTitle,
      faqContent: values.faqContent,
    });
    if (res && res.data) {
      setLoading(false);
      setIsModalOpen(false);
      form.resetFields();
      handleGetListFaqCategory();
    }
  };

  const handleAddNewFaq = async (values: any) => {
    setLoading(true);
    if (!selectedCate || !values.title || !values.content) return;
    const body: CreateNewFaqInput = {
      categoryId: selectedCate._id,
      title: values.title,
      content: values.content,
    };
    const res = await createNewFaq(body);
    if (res && res.data) {
      setLoading(false);
      handleGetListFaqCategory();
      setIsModalNewFaqOpen(false);
      newFaqForm.resetFields();
    }
  };

  const confirmToDeleteCate = async (categoryId: string) => {
    setLoading(true);
    const res = await deleteFaqCategory(categoryId);
    if (res && res.data) {
      handleGetListFaqCategory();
      setLoading(false);
    }
  };

  const handleEditCate = async (categoryId: string) => {
    const values = await editCateForm.validateFields();
    const body: UpdateCategoryInput = {
      title: values.title,
    };
    const res = await updateFaqCategory(categoryId, body);
    if (res && res.data) {
      editCateForm.resetFields();
      setSelectedCateId("");
      setEnableEditCate(!enableEditCate);
      handleGetListFaqCategory();
    }
  };

  const toggleEditCate = (categoryId: string, title: string) => {
    setSelectedCateId(categoryId);
    setEnableEditCate(!enableEditCate);
    editCateForm.setFieldValue("title", title);
  };

  const handleEditFaq = async (values: any) => {
    setLoading(true);
    if (!selectedFaq || !values.title || !values.content) return;
    const res = await updateFaq(selectedFaq._id, {
      title: values.title,
      content: values.content,
    });
    if (res && res.data) {
      setLoading(false);
      setIsModalFaqOpen(false);
      editFaqForm.resetFields();
      setSelectedFaq(undefined);
      handleGetListFaqCategory();
    }
  };

  const confirmToDeleteFaq = async (faqId: string) => {
    setLoading(true);
    const res = await deleteFaq(faqId);
    if (res && res.data) {
      setLoading(false);
      handleGetListFaqCategory();
    }
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <div className="title-page">FAQs</div>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <Button variant="outline-primary" onClick={showModal}>
            Add new
          </Button>
        </Col>
      </Row>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {data.map((item, index) => {
            return (
              <div style={{ marginBottom: "10px" }} key={index}>
                {enableEditCate && selectedCateId === item._id.toString() ? (
                  <Form form={editCateForm} style={{ marginBottom: "10px" }}>
                    <Form.Item
                      style={{ margin: 0 }}
                      name="title"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: `Please input section title!`,
                      //   },
                      // ]}
                    >
                      <Input
                        onPressEnter={() => handleEditCate(item._id)}
                        onBlur={() => handleEditCate(item._id)}
                      />
                    </Form.Item>
                  </Form>
                ) : (
                  <h5
                    style={{ marginBottom: "2px", fontSize: "16px" }}
                    className="title"
                  >
                    {item.title}
                  </h5>
                )}
                <div className="d-flex justify-content-between">
                  <Button
                    variant="success"
                    className="mb-1 p-1"
                    style={{ fontSize: "10px" }}
                    size="sm"
                    onClick={() => showModalNewFaq(item)}
                  >
                    New Faq
                  </Button>
                  <div>
                    <Button
                      variant="warning"
                      className="mb-1 p-1"
                      style={{ fontSize: "10px", marginRight: "2px" }}
                      size="sm"
                      onClick={() => toggleEditCate(item._id, item.title)}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Are you sure to delete this section?"
                      onConfirm={() => confirmToDeleteCate(item._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        variant="secondary"
                        className="mb-1 p-1"
                        style={{ fontSize: "10px" }}
                        size="sm"
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
                <Collapse accordion expandIconPosition="end">
                  {item.faqs &&
                    item.faqs.length > 0 &&
                    item.faqs?.map((faq, id) => {
                      return (
                        <Panel
                          header={
                            <h6 style={{ fontSize: "15px" }}>{faq.title}</h6>
                          }
                          key={faq._id}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <p style={{ width: "90%" }}>{faq.content}</p>
                            <div>
                              <Button
                                variant="warning"
                                className="mb-1 p-1"
                                style={{ fontSize: "10px", marginRight: "2px" }}
                                size="sm"
                                onClick={() => showModalEditFaq(faq)}
                              >
                                Edit
                              </Button>
                              <Popconfirm
                                title="Are you sure to delete this FAQ?"
                                onConfirm={() => confirmToDeleteFaq(faq._id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  variant="secondary"
                                  className="mb-1 p-1"
                                  style={{ fontSize: "10px" }}
                                  size="sm"
                                >
                                  Delete
                                </Button>
                              </Popconfirm>
                            </div>
                          </div>
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>
            );
          })}
        </>
      )}
      <Modal
        title="Add new FAQ section"
        onCancel={handleCancel}
        open={isModalOpen}
        footer={false}
      >
        <Form form={form} onFinish={handleAddNewCateAndFaq} layout="vertical">
          <Form.Item
            label="Section Title"
            name="cateTitle"
            // rules={[{ required: true, message: "Please input section title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Faq Title"
            name="faqTitle"
            rules={[{ required: true, message: "Please input faq title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Faq Content"
            name="faqContent"
            rules={[{ required: true, message: "Please input faq content!" }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>

          <Button
            onClick={handleCancel}
            type="button"
            style={{ marginRight: "6px" }}
            variant="outline-warning"
          >
            Cancel
          </Button>
          <Button type="submit" variant="outline-info">
            Submit
          </Button>
        </Form>
      </Modal>

      {selectedCate && (
        <Modal
          title={`Add new FAQ for ${selectedCate.title}`}
          onCancel={handleCancelNewFaqModal}
          open={isModalNewFaqOpen}
          footer={false}
        >
          <Form form={newFaqForm} onFinish={handleAddNewFaq} layout="vertical">
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Content"
              name="content"
              rules={[{ required: true, message: "Please input content!" }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>

            <Button
              onClick={handleCancelNewFaqModal}
              type="button"
              style={{ marginRight: "6px" }}
              variant="outline-warning"
            >
              Cancel
            </Button>
            <Button type="submit" variant="outline-info">
              Submit
            </Button>
          </Form>
        </Modal>
      )}

      {selectedFaq && (
        <Modal
          title={`Edit - ${truncateString(selectedFaq.title, 32)}`}
          onCancel={handleCancelEditFaqModal}
          open={isModalEditFaqOpen}
          footer={false}
        >
          <Form form={editFaqForm} onFinish={handleEditFaq} layout="vertical">
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Content"
              name="content"
              rules={[{ required: true, message: "Please input content!" }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>

            <Button
              onClick={handleCancelEditFaqModal}
              type="button"
              style={{ marginRight: "6px" }}
              variant="outline-warning"
            >
              Cancel
            </Button>
            <Button type="submit" variant="outline-info">
              Submit
            </Button>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default FAQsPage;
