import axiosClient from "../../utils/axiosClient";

export interface IParamCompany {
  limit: number;
  page: number;
  search?: string;
}

export interface IDataTable {
  _id: string;
  name?: string;
  email?: string;
  status: string;
  isVerified?: boolean;
  approve?: string;
  createdAt?: any;
}

export interface IDataChangeStatus {
  id: string;
  status: string;
}

export const getListCompany = async (params: IParamCompany) => {
  const res = await axiosClient.get("/company", params);
  return res;
};

export const onChangeStatus = async (data: IDataChangeStatus) => {
  const res = await axiosClient.post(`/company/change-status/${data.id}`, {
    status: data.status,
  });
  return res;
};

export const onChangeApproveCompany = async (data: {
  id: string;
  status: string;
}) => {
  const res = await axiosClient.post(`/company/approve/${data.id}`, {
    status: data.status,
  });
  return res;
};
