import axiosClient from "../utils/axiosClient";

export interface Media {
  _id: string;
  url: string;
  width: number;
  height: number;
  size: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export const onUploadImageToCloud = (formData: FormData) => {
  return axiosClient.postFormData("medias/upload-cloud", formData);
};

export const onUploadImage = (formData: FormData) => {
  return axiosClient.postFormData("medias/upload", formData);
};

export const onUploadBulkImages = (formData: FormData) => {
  return axiosClient.postFormData("medias/bulk-upload-cloud", formData);
};
