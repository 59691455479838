import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Pagination, Row, Spinner, Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import useDebounce from '../../hooks/useDebounce';
import { convertCase } from '../../utils/utils';
import { getListCompany, IDataTable, IParamCompany, onChangeApproveCompany, onChangeStatus } from './services';

const CompanyPage: React.FC<{}> = (props) => {
    const [dataTable, setDataTable] = useState<IDataTable[]>([])

    const [filter, setFilter] = useState<IParamCompany>({
        page: 1,
        limit: 10,
        search: undefined
    })
    const [search, setSearch] = useState<string>('')
    const debouncedValue = useDebounce<string>(search, 500)

    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [textError, setTextError] = useState<string>()

    const [loadingCheck, setLoadingCheck] = useState<boolean>(false)

    useEffect(() => {
        const filterData = { ...filter, search: debouncedValue }
        onGetListDataTable(filterData)
    }, [filter, debouncedValue])

    const onGetListDataTable = async (filter: IParamCompany) => {
        setLoading(true)
        try {
            const res = await getListCompany(filter)
            if (res && res?.status === "SUCCESS") {
                setDataTable(res?.data)
                setTotal(res?.total || 0)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setDataTable([])
            setTotal(0)
        }
    }

    const onChangeChecked = async (dataChecked: any, data: IDataTable, i: number) => {
        setTextError(undefined)
        const { checked } = dataChecked?.target
        const status = checked ? "ACTIVE" : "INACTIVE"
        const params = { id: data._id, status: status }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <button className="btn-close" onClick={onClose}>x</button>
                        <div className='title'>Lock Account</div>
                        <div className='content'>{`Are you sure you want to ${convertCase(status)} account: ${data?.email || data?.name}?`}</div>

                        <div className='mt-3' style={{ textAlign: "right" }}>
                            <Button variant='primary' size="sm"
                                onClick={async () => {
                                    setLoadingCheck(true)
                                    try {
                                        const res = await onChangeStatus(params)
                                        if (res && res?.status) {
                                            switch (res.status) {
                                                case "SUCCESS":
                                                    const newDataTable = [...dataTable]
                                                    newDataTable[i].status = status
                                                    setDataTable(newDataTable)
                                                    onClose()
                                                    setLoadingCheck(false)
                                                    break;
                                                default:
                                                    setTextError(`${convertCase(res?.status)} : ${data?.name}`)
                                                    onClose()
                                                    setLoadingCheck(false)
                                                    break;
                                            }
                                        }
                                    } catch (error: any) {
                                        setLoading(false)
                                        setTextError(`${convertCase(error?.message)}`)
                                        onClose()
                                    }
                                }}
                            >
                                {loadingCheck
                                    ? <span>
                                        <span className="spinner-border spinner-border-sm mright-5" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </span>
                                    : "Confirm"
                                }
                            </Button>
                            <Button style={{ marginLeft: 10 }} variant="danger" size="sm"
                                onClick={onClose}
                            >Cancel</Button>
                        </div>
                    </div >
                );
            },
            overlayClassName: "overlayClassName",
            closeOnClickOutside: false
        });
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }

    const onChangeApprove = async (dataChecked: any, data: IDataTable, i: number) => {
        setTextError(undefined)
        const { checked } = dataChecked?.target
        const params = { id: data._id, status: "APPROVED" }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <button className="btn-close" onClick={onClose}>x</button>
                        <div className='title'>Approved Account</div>
                        <div className='content'>{`Are you sure you want to ${convertCase("APPROVED")} account: ${data?.email || data?.name}?`}</div>

                        <div className='mt-3' style={{ textAlign: "right" }}>
                            <Button variant='primary' size="sm"
                                onClick={async () => {
                                    try {
                                        const res = await onChangeApproveCompany(params)
                                        if (res && res?.status) {
                                            switch (res.status) {
                                                case "SUCCESS":
                                                    setFilter({ ...filter })
                                                    onClose()
                                                    break;
                                                default:
                                                    setTextError(`${convertCase(res?.status)} : ${data?.name}`)
                                                    onClose()
                                                    break;
                                            }
                                        }
                                    } catch (error: any) {
                                        setTextError(`${convertCase(error?.message)}`)
                                        onClose()
                                    }
                                }}
                            >Confirm</Button>
                            <Button style={{ marginLeft: 10 }} variant="danger" size="sm"
                                onClick={onClose}
                            >Cancel</Button>
                        </div>
                    </div >
                );
            },
            overlayClassName: "overlayClassName",
            closeOnClickOutside: false
        });


    }

    const onChangePage = (number: number) => {
        setFilter({ ...filter, page: number })
    }

    const renderPagination = (totalTable: number) => {
        const limitPage = Math.ceil(totalTable / filter.limit)
        const active = filter.page;
        const items = [];
        for (let number = 1; number <= limitPage; number++) {
            items.push(
                <Pagination.Item onClick={() => onChangePage(number)} key={number} active={number === active}>
                    {number}
                </Pagination.Item>
            );
        }

        return <Pagination>{items}</Pagination>
    }

    return (
        <div>
            <div className='title-page'>Company</div>
            <Row>
                <Col xs={6}>
                    <Form.Control placeholder="Search ..." onChange={handleChange} />
                </Col>
                <Col xs={12}>
                    {textError
                        ? <Alert key={"danger"} variant={"danger"}>
                            {textError}
                        </Alert>
                        : ""
                    }
                    <Table hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Created At</th>
                                <th>Lock Account</th>
                                <th>Approve</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading
                                ? <tr>
                                    <td colSpan={6} className="text-center">
                                        <div className='mt-2 mb-2'>
                                            <Spinner animation="border" />
                                        </div>
                                    </td>
                                </tr>
                                : isEmpty(dataTable)
                                    ? <tr>
                                        <td colSpan={6} className="text-center">No Data</td>
                                    </tr>
                                    : dataTable.map((d, i) => {
                                        return (
                                            <tr key={`table_${i + 1}`}>
                                                <td>{i + 1}</td>
                                                <td>{d?.name}</td>
                                                <td>{d?.email}</td>
                                                <td>{moment(d?.createdAt).format('YYYY-MM-DD')}</td>
                                                <td>
                                                    <Form.Check
                                                        type="switch" checked={Boolean(d?.status === "ACTIVE")}
                                                        onChange={(checked) => onChangeChecked(checked, d, i)}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Check
                                                        type="switch" checked={Boolean(d?.approve === "APPROVED")}
                                                        onChange={(checked) => onChangeApprove(checked, d, i)}
                                                        disabled={Boolean(d?.approve === "APPROVED")}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                            }
                        </tbody>
                    </Table>

                    {total > 0
                        ? <div>
                            <Row>
                                <Col xs={12} className="text-right">
                                    {renderPagination(total)}
                                </Col>
                            </Row>
                        </div>
                        : ""
                    }
                </Col>
            </Row>
        </div>
    );
};

export default CompanyPage;