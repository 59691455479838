import React from "react";

export const CampaignIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M16 0.999955C15.9999 0.829563 15.9563 0.662021 15.8733 0.513226C15.7902 0.364431 15.6706 0.239319 15.5256 0.14976C15.3806 0.0602015 15.2152 0.00916769 15.045 0.00150116C14.8748 -0.00616537 14.7054 0.0297896 14.553 0.105954L6.763 3.99995H3C2.20435 3.99995 1.44129 4.31603 0.87868 4.87863C0.316071 5.44124 0 6.20431 0 6.99995C0 7.7956 0.316071 8.55867 0.87868 9.12127C1.44129 9.68388 2.20435 9.99995 3 9.99995H3.28L5.051 15.316C5.11735 15.5152 5.24472 15.6884 5.41505 15.8112C5.58538 15.934 5.79004 16 6 16H7C7.26522 16 7.51957 15.8946 7.70711 15.7071C7.89464 15.5195 8 15.2652 8 15V10.618L14.553 13.894C14.7054 13.9701 14.8748 14.0061 15.045 13.9984C15.2152 13.9907 15.3806 13.9397 15.5256 13.8501C15.6706 13.7606 15.7902 13.6355 15.8733 13.4867C15.9563 13.3379 15.9999 13.1703 16 13V0.999955Z"
        fill="#444444"
      />
    </svg>
  );
};
