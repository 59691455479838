import React, { useEffect, useState } from "react";
import { getListOrder } from "./service";
import { Col, Pagination, Row, Spinner, Table } from "react-bootstrap";
import moment from "moment";
import { truncateString } from "../../utils/utils";

const OrderPage = () => {
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
  });
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginateItems, setPaginateItems] = useState<any[]>([]);

  const handleGetOrderListList = async (params: {
    page: number;
    limit: number;
  }) => {
    setLoading(true);
    const res = await getListOrder(params);
    if (res && res.data) {
      setLoading(false);
      setData(res.data);
      let active = params.page;
      let paginateItems = [];
      for (
        let number = 1;
        number <= Math.round(res.total / params.limit);
        number++
      ) {
        paginateItems.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => onChangePage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
      setPaginateItems(paginateItems);
    } else {
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetOrderListList(params);
  }, [params]);

  const onChangePage = (page: number) => {
    setParams({ ...params, page });
  };
  return (
    <div>
      <div className="title-page">Withdraw Request</div>
      {loading ? (
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr className="text-center">
                  <th>Code</th>
                  <th>Creator</th>
                  <th>Created Time</th>
                  <th>Items</th>
                  <th>Amount</th>
                  <th>Price</th>
                  <th>Delivery Information</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((item: any, index: number) => {
                    return (
                      <tr key={index} className="text-center items-center">
                        <td
                          style={{
                            width: "50px",
                            fontSize: "13px",
                            verticalAlign: "middle",
                          }}
                        >
                          {item.code}
                        </td>
                        <td
                          style={{
                            width: "250px",
                            fontSize: "13px",
                            verticalAlign: "middle",
                          }}
                        >
                          <p style={{ marginBottom: 0 }}>{item.creator.name}</p>
                          <p style={{ marginBottom: 0, fontSize: "11px" }}>
                            {item.creator.email}
                          </p>
                        </td>
                        <td
                          style={{
                            width: "200px",
                            overflowX: "auto",
                            fontSize: "13px",
                            fontWeight: "400",
                            verticalAlign: "middle",
                          }}
                        >
                          {moment(item.createdAt).format("HH:mm MM/DD/YYYY")}
                        </td>
                        <td
                          style={{
                            width: "350px",
                            fontSize: "13px",
                            fontWeight: "400",
                          }}
                        >
                          {item.collections.length > 0 &&
                            item.collections.map((it: any, index: number) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <img
                                    src={it.avatar.image}
                                    width={60}
                                    alt={it.name}
                                    style={{
                                      border: "1px solid #d1d1d1",
                                      borderRadius: "3px",
                                    }}
                                  />
                                  <span>{truncateString(it.name, 32)}</span>
                                </div>
                              );
                            })}
                        </td>
                        <td
                          style={{
                            width: "100px",
                            fontSize: "13px",
                            fontWeight: "400",
                            verticalAlign: "middle",
                          }}
                        >
                          <code>{item.amount}</code>
                        </td>
                        <td
                          style={{
                            width: "150px",
                            fontSize: "13px",
                            fontWeight: "400",
                            verticalAlign: "middle",
                          }}
                        >
                          <code>${item.price?.toFixed(2) || 0}</code>
                        </td>
                        <td
                          style={{
                            width: "400px",
                            overflowX: "auto",
                            fontSize: "12px",
                            fontWeight: "400",
                            verticalAlign: "middle",
                          }}
                        >
                          <p style={{ marginBottom: 0 }}>
                            Shipping Method: {item.shippingMethod}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            Contact: {item.firstName + " " + item.lastName}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            Phone: {item.receivePhone}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            Country: {item.receiveAddress.country}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            Address: {item.receiveAddress.address}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Pagination size="sm">{paginateItems}</Pagination>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default OrderPage;
