import "react-confirm-alert/src/react-confirm-alert.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { AuthProvider } from "./components/AuthContext";
import LayoutAdmin from "./layout/LayoutAdmin";
import LayoutAuth from "./layout/LayoutAuth";
import LoginPage from "./pages/Auth/Login";
import CampaignLayout from "./pages/Campaign/CampaignLayout";
import CampaignExpired from "./pages/Campaign/components/CampaignExpired";
import CampaignRunning from "./pages/Campaign/components/CampaignRunning";
import CompanyPage from "./pages/Company";
import PendingWithdrawRequest from "./pages/Withdraw/Pending";
import WithdrawRequest from "./pages/Withdraw/List";
import FAQsPage from "./pages/FAQs";
import About from "./pages/About";
import OrderPage from "./pages/Order";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/auth" element={<LayoutAuth />}>
          <Route index element={<LoginPage />}></Route>
          <Route path="login" element={<LoginPage />}></Route>
        </Route>

        {/* CMS */}
        <Route path="/" element={<LayoutAdmin />}>
          <Route path="/" element={<Navigate to="/company" replace />} />
          <Route path="company" element={<CompanyPage />}></Route>

          <Route path="campaign" element={<CampaignLayout />}>
            <Route index element={<CampaignRunning />}></Route>
            <Route path="running" element={<CampaignRunning />}></Route>
            <Route path="expired" element={<CampaignExpired />}></Route>
          </Route>
          <Route
            path="pending-withdraw-request"
            element={<PendingWithdrawRequest />}
          ></Route>
          <Route path="withdraw-request" element={<WithdrawRequest />}></Route>
          <Route path="faq" element={<FAQsPage />}></Route>
          <Route path="about" element={<About />}></Route>
          <Route path="order" element={<OrderPage />}></Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
