/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import {
  Modal,
  Form,
  Input,
  Upload,
  Avatar,
  Tooltip,
  message,
  Spin,
  Table,
  Drawer,
  Tag,
  Popconfirm,
} from "antd";
import {
  BarsOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import DefaultImgAvatar from "../../components/DefaultImgAvatar";

import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { Media, onUploadBulkImages, onUploadImage } from "../../services/media";
import {
  ConfigInput,
  modifyConfig,
  Configs,
  getConfig,
  Post,
  getListPost,
  CreatePostInput,
  createPost,
  updatePost,
  deletePost,
  removeAboutPageMedia,
} from "./service";
import { getBase64 } from "../../utils/utils";

const About = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalSectionOpen, setIsModalSectionOpen] = useState<boolean>(false);

  const [imgUrls, setImgUrls] = useState<Media[]>([]);
  const [configData, setConfigData] = useState<Configs>();
  const [posts, setPosts] = useState<Post[]>([]);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectedPost, setSelectedPost] = useState<Post>();
  const [postImgUrl, setPostImgUrl] = useState<string>();
  const [postFile, setPostFile] = useState<UploadFile>();

  const [sectionForm] = Form.useForm();
  const [postForm] = Form.useForm();

  const handleGetConfig = async () => {
    const res = await getConfig();
    if (res && res.data) {
      setConfigData(res.data);
      sectionForm.setFieldsValue({
        aboutPageContent: res.data.aboutPageContent || "",
        aboutPageSummary: res.data.aboutPageSummary || "",
      });
      setImgUrls(res.data.aboutPageMedias);
      // const mediaUrls: string[] = [];
      // res.data.aboutPageMedias.map((item: Media) => {
      //   mediaUrls.push(item.url);
      // });
      // setImgUrls(mediaUrls);
    }
  };

  const handleGetPost = async () => {
    const res = await getListPost();
    if (res && res.data) {
      setPosts(res.data);
    }
  };

  useEffect(() => {
    handleGetConfig();
    handleGetPost();
  }, []);

  const handleOpenModalSection = () => {
    setIsModalSectionOpen(true);
  };

  const handleCloseModalSection = () => {
    setIsModalSectionOpen(false);
    sectionForm.resetFields();
  };

  const handleSaveSectionConfig = async (values: any) => {
    const configBody: ConfigInput = {
      aboutPageContent: values.aboutPageContent,
      aboutPageSummary: values.aboutPageSummary,
    };
    const updated = await handleModifyConfig(configBody);
    if (updated) {
      message.success("Updated Successfully!");
      handleCloseModalSection();
      handleGetConfig();
    }
  };

  const handleModifyConfig = async (body: ConfigInput) => {
    let is = false;
    const sectionRes = await modifyConfig(body);
    if (sectionRes && sectionRes.data) {
      is = true;
    }
    return is;
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt5M = Number(file.size) / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }

    return (isJpgOrPng || Upload.LIST_IGNORE) && isLt5M;
  };

  const onChangeFile = async (info: UploadChangeParam<UploadFile>) => {
    if (
      info.file.status === "uploading" ||
      !info.file ||
      !info.file.originFileObj
    )
      return;
    setUploadLoading(true);
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("file", info.file.originFileObj as RcFile);
      const res = await onUploadImage(formData);
      if (res && res.data) {
        const sectionRes = await handleModifyConfig({
          aboutPageMediaId: res.data._id,
        });
        if (sectionRes) {
          setUploadLoading(false);
          message.success("Upload Successfully!");
        } else {
          setUploadLoading(false);
          message.success("Internal Error");
        }
      } else {
        setUploadLoading(false);
        message.success("Internal Error");
      }
      handleGetConfig();
    }
  };

  const uploadButton = (
    <div className="upload-button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onDeletePost = async (_id: string) => {
    const res = await deletePost(_id);
    if (res && res.data) {
      message.success("Delete Successfully");
      handleGetPost();
    }
  };

  const onEditPostClicked = (post: Post) => {
    setSelectedPost(post);
    setPostImgUrl(post.avatar?.url);
    showDrawer();
    postForm.setFieldsValue({
      title: post.title,
      content: post.content,
      avatarId: post.avatarId,
    });
  };

  const renderAction = (value: any, data: Post, index: number) => {
    return (
      <div className="flex render-action-style justiv">
        <Tooltip placement="top" title="Edit Post">
          <Tag
            color="#08c"
            style={{
              paddingRight: "10px",
            }}
            onClick={() => onEditPostClicked(data)}
          >
            <EditOutlined />
          </Tag>
        </Tooltip>

        <Tooltip placement="top" title="Delete Post">
          <Popconfirm
            placement="topRight"
            title="Are you sure?"
            onConfirm={() => onDeletePost(data._id)}
            okText="Ok"
            cancelText="Cancel"
          >
            <Tag color="#9c27b0">
              <DeleteOutlined />
            </Tag>
          </Popconfirm>
        </Tooltip>
      </div>
    );
  };

  const columns: ColumnType<any>[] = [
    {
      title: "#",
      key: "_id",
      render: (record: any, data: any, index: any) => {
        return (
          <Avatar
            size={40}
            src={data.avatar.url}
            style={{ border: "1px solid #a955b3" }}
          />
        );
      },
      width: "90px",
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (title: string) => {
        return title;
      },
      width: "250px",
    },
    {
      title: "Content",
      dataIndex: "content",
      render: (content: string) => {
        return content;
      },
    },
    {
      title: <BarsOutlined />,
      dataIndex: "_id",
      align: "center",
      render: renderAction,
    },
  ];

  const uploadPostButton = (
    <div>
      {uploadLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <DefaultImgAvatar size={80} />
      )}
    </div>
  );

  const handleChangePostFile = (info: UploadChangeParam<UploadFile>) => {
    setUploadLoading(true);

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setPostImgUrl(url);
      });
      setPostFile(info.file);
      setUploadLoading(false);
    }
  };

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    postForm.resetFields();
    setPostImgUrl(undefined);
    setPostFile(undefined);
  };

  const handleFinishPost = async (values: any) => {
    setLoading(true);
    if (!selectedPost) {
      if (!postFile) return;
      const formData = new FormData();
      formData.append("file", postFile.originFileObj as RcFile);

      const res = await onUploadImage(formData);
      if (res && res.data) {
        const body: CreatePostInput = {
          title: values.title,
          content: values.content,
          avatarId: res.data._id,
        };
        await createPost(body);
        onCloseDrawer();
        handleGetPost();
        setLoading(false);
      }
    } else {
      let avatarId = selectedPost.avatarId;
      if (postFile) {
        const formData = new FormData();
        formData.append("file", postFile.originFileObj as RcFile);

        const res = await onUploadImage(formData);
        if (res && res.data) {
          avatarId = res.data._id;
        }
      }
      const body: CreatePostInput = {
        title: values.title,
        content: values.content,
        avatarId,
      };
      await updatePost(selectedPost._id, body);
      onCloseDrawer();
      handleGetPost();
      setLoading(false);
    }
  };

  const onDeleteAboutImage = async (_id: string) => {
    const res = await removeAboutPageMedia(_id);
    if (res && res.data) {
      message.success("Delete Successfully");
      handleGetConfig();
    }
  };

  return (
    <div>
      <Row>
        <Col md={12}>
          <div className="title-page">About Koopon</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Tooltip title="Click to modify">
              <p
                onClick={handleOpenModalSection}
                style={{
                  border: "1px dotted #e8e8e8",
                  width: "100%",
                  padding: "10px",
                  textAlign: "center",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
              >
                {configData && configData.aboutPageSummary
                  ? configData.aboutPageSummary
                  : "Section 1"}
              </p>
            </Tooltip>
            {uploadLoading ? (
              <Spin tip="Loading...">
                <Avatar.Group>
                  {imgUrls.length > 0 ? (
                    imgUrls.map((img, index) => {
                      return (
                        <DefaultImgAvatar
                          key={index}
                          src={img.url}
                          size={100}
                        />
                      );
                    })
                  ) : (
                    <DefaultImgAvatar size={100} />
                  )}
                  <Upload
                    beforeUpload={beforeUpload}
                    accept={"image/png, image/jpeg"}
                    action={`${process.env.REACT_APP_API_ENDPOINT}/medias`}
                    showUploadList={false}
                    name="file"
                    onChange={onChangeFile}
                    style={{ cursor: "pointer" }}
                  >
                    {uploadButton}
                  </Upload>
                </Avatar.Group>
              </Spin>
            ) : (
              <Avatar.Group>
                {imgUrls.length > 0 ? (
                  imgUrls.map((img, index) => {
                    return (
                      <DefaultImgAvatar
                        enableDelete
                        handleDelete={onDeleteAboutImage}
                        key={index}
                        src={img.url}
                        size={100}
                        mediaId={img._id}
                      />
                    );
                  })
                ) : (
                  <DefaultImgAvatar size={100} />
                )}
                <Upload
                  beforeUpload={beforeUpload}
                  accept={"image/png, image/jpeg"}
                  action={`${process.env.REACT_APP_API_ENDPOINT}/medias`}
                  showUploadList={false}
                  name="file"
                  onChange={onChangeFile}
                  style={{ cursor: "pointer" }}
                >
                  {uploadButton}
                </Upload>
              </Avatar.Group>
            )}

            <Tooltip title="Click to modify">
              <p
                onClick={handleOpenModalSection}
                style={{
                  border: "1px dotted #e8e8e8",
                  width: "100%",
                  padding: "10px",
                  textAlign: "center",
                  borderRadius: "15px",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                {configData && configData.aboutPageContent
                  ? configData.aboutPageContent
                  : "Section 2"}
              </p>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="title-page">About Posts</div>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <Button onClick={showDrawer} variant="outline-primary">
            Add new post
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          size="middle"
          loading={loading}
          dataSource={posts || []}
          columns={columns}
          pagination={false}
          scroll={{ x: "auto" }}
          rowKey="_id"
        ></Table>
      </Row>
      <Drawer
        width={650}
        placement="right"
        closable={false}
        onClose={onCloseDrawer}
        visible={visibleDrawer}
      >
        <Form form={postForm} layout="vertical" onFinish={handleFinishPost}>
          <Form.Item
            name="avatarId"
            rules={[
              {
                required: true,
                message: "Post image is required",
              },
            ]}
            style={{
              textAlign: "center",
            }}
          >
            <Upload
              listType="picture-card"
              accept={"image/png, image/jpeg"}
              showUploadList={false}
              action={`${process.env.REACT_APP_API_ENDPOINT}/medias`}
              onChange={handleChangePostFile}
              beforeUpload={beforeUpload}
              style={{
                width: "90px",
                height: "90px",
              }}
            >
              {postImgUrl ? (
                <DefaultImgAvatar src={postImgUrl} size={80} />
              ) : (
                uploadPostButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Content"
            name="content"
            rules={[{ required: true, message: "Please input content!" }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          {loading ? (
            <Spin tip="Loading...">
              <Button
                onClick={onCloseDrawer}
                type="button"
                style={{ marginRight: "6px" }}
                variant="outline-warning"
              >
                Cancel
              </Button>
              <Button type="submit" variant="outline-info">
                Submit
              </Button>
            </Spin>
          ) : (
            <>
              <Button
                onClick={onCloseDrawer}
                type="button"
                style={{ marginRight: "6px" }}
                variant="outline-warning"
              >
                Cancel
              </Button>
              <Button type="submit" variant="outline-info">
                Submit
              </Button>
            </>
          )}
        </Form>
      </Drawer>

      <Modal
        title={"About Koopon"}
        onCancel={handleCloseModalSection}
        open={isModalSectionOpen}
        footer={false}
      >
        <Form
          form={sectionForm}
          onFinish={handleSaveSectionConfig}
          layout="vertical"
        >
          <Form.Item
            label="Section 1"
            name="aboutPageSummary"
            rules={[{ required: true, message: "Please input content!" }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>

          <Form.Item
            label="Section 2"
            name="aboutPageContent"
            rules={[{ required: true, message: "Please input content!" }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>

          <Button
            onClick={handleCloseModalSection}
            type="button"
            style={{ marginRight: "6px" }}
            variant="outline-warning"
          >
            Cancel
          </Button>
          <Button type="submit" variant="outline-info">
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default About;
