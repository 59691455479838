import { RcFile } from "antd/es/upload";
import React from "react";
import { useLocation } from "react-router-dom";

const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const convertCase = (text: string) => {
  const lowercase = text.toLowerCase().replaceAll("_", " ");
  return capitalizeFirstLetter(lowercase);
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const truncateString = (str: string, length = 50): string => {
  return str.length > length ? str.substring(0, length - 3) + "..." : str;
};

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
