import { get } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import imageItem from "../../../assets/images/image_item.png";
import { convertCase } from "../../../utils/utils";
import { IDataCollection, onDeleteCollection } from "../service";

interface Props {
  data: IDataCollection[];
  onReloadTable: () => void;
}

const CampaignCard: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <>
      <Row className="mt-1">
        {data && data.length > 0
          ? data.map((d: IDataCollection, key: number) => (
              <Col
                xs={4}
                className="mb-4 hover-box-content-item"
                key={`data_key_${key + 1}`}
              >
                <div className="box-content-item">
                  <img
                    src={get(d, "avatar.image") || imageItem}
                    width={"100%"}
                    className="image-box-compaign"
                  />
                  <div className="padding-box">
                    <div className="d-flex-between">
                      <div className="expiry-date">
                        <span className="opacity">Expires</span> •{" "}
                        {moment(d.expiredDate).format("MM/DD/YYYY")}
                      </div>
                      <div className="expiry-date">
                        <span className="opacity">Catagory</span> •{" "}
                        {convertCase(d.category)}
                      </div>
                      <div className="expiry-date">
                        <span className="opacity">Symbol</span> • {d.symbol}
                      </div>
                    </div>
                    <div className="ntf-compaign">{d.name || d.nftName}</div>
                    <div
                      className="compaign-content f-14"
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    ></div>
                  </div>
                </div>
              </Col>
            ))
          : ""}
      </Row>
    </>
  );
};

export default CampaignCard;
